// Dependencies
import React from "react"
// Hooks
import useSettings from "../hooks/useSettings"
// Components
import Layout from "../components/Layout"
import Markdown from "../components/Markdown"

const HoldingPage = () => {

  const { holdingPageCopy } = useSettings();

  return (
    <Layout>
      <div className="flex-center fv">
        <Markdown className="max mauto align-center" field={holdingPageCopy} />
      </div>
    </Layout>
  )
}

export default HoldingPage
